// Validation helper for DebitInformationForm
const validateDebit = values => {
    const errors = {};

    // Account Holder Information
    if (!values.debitName) {
        errors.debitName = 'Name is required';
    }

    if (!values.debitStreet) {
        errors.debitStreet = 'Street is required';
    }

    if (!values.debitHouseNumber) {
        errors.debitHouseNumber = 'House number is required';
    }

    if (!values.debitZipcode) {
        errors.debitZipcode = 'Zipcode is required';
    }

    if (!values.debitCity) {
        errors.debitCity = 'City is required';
    }

    if (!values.debitCountry) {
        errors.debitCountry = 'Country is required';
    }

    // Bank Information
    if (!values.debitIban) {
        errors.debitIban = 'IBAN is required';
    } else if (!/^[A-Z]{2}[0-9]{2}[A-Z0-9]{4}[0-9]{7}([A-Z0-9]?){0,16}$/i.test(values.debitIban)) {
        errors.debitIban = 'Invalid IBAN format';
    }

    if (!values.debitBic) {
        errors.debitBic = 'BIC is required';
    } else if (!/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/i.test(values.debitBic)) {
        errors.debitBic = 'Invalid BIC format';
    }

    if (!values.debitBankName) {
        errors.debitBankName = 'Bank name is required';
    }

    // Alternate Account Holder
    if (values.hasAlternateHolder === 'yes') {
        if (!values.altHolderName) {
            errors.altHolderName = 'Alternate account holder name is required';
        }

        if (!values.altHolderStreet) {
            errors.altHolderStreet = 'Alternate account holder street is required';
        }

        if (!values.altHolderZipcode) {
            errors.altHolderZipcode = 'Alternate account holder zipcode is required';
        }

        if (!values.altHolderCity) {
            errors.altHolderCity = 'Alternate account holder city is required';
        }

        if (!values.altHolderCountry) {
            errors.altHolderCountry = 'Alternate account holder country is required';
        }
    }

    return errors;
};

export default validateDebit;
