// Validation helper for LoaInformationForm
const validateLoa = values => {
    const errors = {};

    // Company Information
    if (!values.loaCompany) {
        errors.loaCompany = 'Company name is required';
    }

    if (!values.loaCompanyType) {
        errors.loaCompanyType = 'Company type is required';
    }

    if (!values.loaFirstName) {
        errors.loaFirstName = 'First name is required';
    }

    if (!values.loaLastName) {
        errors.loaLastName = 'Last name is required';
    }

    if (!values.loaVat) {
        errors.loaVat = 'VAT number is required';
    }

    // Address Information
    if (!values.loaStreet) {
        errors.loaStreet = 'Street is required';
    }

    if (!values.loaNumber) {
        errors.loaNumber = 'House number is required';
    }

    if (!values.loaZipcode) {
        errors.loaZipcode = 'Zipcode is required';
    }

    if (!values.loaCity) {
        errors.loaCity = 'City is required';
    }

    // Service Information
    if (!values.loaHasServices) {
        errors.loaHasServices = 'Please indicate if you have existing services';
    }

    // Additional validations for when hasServices is 'yes' and not DIY
    if (values.loaHasServices === 'yes' && !values.loaDiy) {
        if (!values.loaCurrentProvider) {
            errors.loaCurrentProvider = 'Current provider is required';
        }

        if (!values.loaCustomerNumber) {
            errors.loaCustomerNumber = 'Customer number is required';
        }

        if (!values.loaEasySwitchID) {
            errors.loaEasySwitchID = 'Easy Switch ID is required';
        }

        // Validate phone numbers array if it exists
        if (values.loaNumbers && values.loaNumbers.length > 0) {
            const numbersArrayErrors = [];
            values.loaNumbers.forEach((number, index) => {
                const numberErrors = {};
                if (!number || !number.number) {
                    numberErrors.number = 'Phone number is required';
                    numbersArrayErrors[index] = numberErrors;
                } else if (!/^[0-9]{10}$/i.test(number.number)) {
                    numberErrors.number = 'Invalid phone number format';
                    numbersArrayErrors[index] = numberErrors;
                }
            });
            if (numbersArrayErrors.length > 0) {
                errors.loaNumbers = numbersArrayErrors;
            }
        }
    }

    return errors;
};

export default validateLoa;
