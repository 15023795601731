import React from 'react';
import {Field, getFormValues, reduxForm} from 'redux-form';
import renderField from '../../components/blocks/renderField';
import validate from '../validate';
import {connect} from 'react-redux';
import InstallationDatePicker from './InstallationDatePicker';
import InstallationTimePicker from './InstallationTimePicker';
import moment from 'moment';
import InstallationImages from './InstallationImages';

const today = new Date();
const now = moment();
const end = moment().add(90, 'minutes');

class InstallationForm extends React.Component {

    componentDidMount() {
        const {initialized} = this.props;

        if (!initialized) {
            this.populateForm();
        }
    }

    componentDidUpdate(prevProps) {
        const {installation} = this.props;

        if (prevProps.installation !== installation) {
            this.populateForm();
        }
    }

    populateForm = () => {
        const {installation} = this.props;
        const {id, street, number, appendix, zipcode, city, initials, infix, last_name, phone, contact, company, email, start, date} = installation;

        const startTime = moment(start, 'HH:mm');
        const endTime = moment(start, 'HH:mm').add('90', 'minutes');

        this.props.change('id', id);
        this.props.change('initials', initials);
        this.props.change('infix', infix);
        this.props.change('lastname', last_name);
        this.props.change('company', company);
        this.props.change('phone', phone);
        this.props.change('contact', contact);
        this.props.change('email', email);
        this.props.change('street', street);
        this.props.change('number', number);
        this.props.change('appendix', appendix);
        this.props.change('zipcode', zipcode);
        this.props.change('city', city);
        this.props.change('installationStart', {hour: parseInt(startTime.format('HH')), minute: parseInt(startTime.format('mm'))});
        this.props.change('installationEnd', {hour: parseInt(endTime.format('HH')), minute: parseInt(endTime.format('mm'))});
        this.props.change('installationDay', moment(date).toDate());

    }

    render() {
        const {handleSubmit, formData , installation} = this.props;
        const props = this.props;

        if (!formData) {
            return null;
        }

        return (
            <form onSubmit={handleSubmit}>
                <h4 className="mt-4">Installatie</h4>
                <div className="listOptionListContainer p-4 mt-3">

                    <div className="row mt-1">
                        <div className="col-12 col-xl-4 col-sm-6">
                            <label>Datum:</label>
                            <div className="input-group">
                                <Field name="installationDay" type="date" component={props => {
                                    return <InstallationDatePicker value={props.input.value} onChange={value => props.input.onChange(value)} />;
                                }} label="Datum" />
                            </div>
                        </div>
                        <div className="col-12 col-xl-8 col-sm-6">
                            <div className="row">
                                <div className="col-12 col-xl-6">
                                    <label>Begin Tijd:</label>
                                    <div className="input-group">
                                        <Field name="installationStart" component={InstallationTimePicker} label="Start tijd" />
                                    </div>
                                </div>
                                <div className="col-12 col-xl-6">
                                    <label>Eind Tijd:</label>
                                    <div className="input-group">
                                        <Field name="installationEnd" component={InstallationTimePicker} label="Eind tijd" />
                                    </div>
                                </div>

                                <div className="col-12 mt-2">
                                    <label>Meters Kabel:</label>
                                    <div className="input-group">
                                        <Field name="installationCable" type="number" component={renderField} label="Kabel Lengte in Meters"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row mt-1">
                        <div className="col-12">
                            <div className="form-group">
                                <label>Opmerkingen:</label>
                                <Field id="installationDescription" name="installationDescription" type="textarea" component={renderField}/>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-1">
                        <div className="col-12">
                            <div className="form-group">
                                <label>Foto's:</label>
                                <Field name="installationImages" component={props => {
                                    return <InstallationImages value={props.input.value} onChange={value => props.input.onChange(value)} />
                                }} label="Foto's" />
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row mt-5">
                    <div className="col-md-4" />
                    <div className="col-md-4" />
                    <div className="col-md-4">
                        <button type="submit" className="btn btn-sm btn-block">Volgende stap</button>
                    </div>
                </div>
            </form>
        );
    }

}

InstallationForm = reduxForm({
    form: 'wizard',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    initialValues: {
        id: '',
        telephoneType: 'none',
        contact: '',
        phoneNumbers: [""],
        contractDetails: 'asap',
        paymentFrequency: 'monthly',
        street: '',
        zipcode: '',
        number: '',
        appendix: '',
        city: '',
        differentInvoiceAddress: false,
        ippin: true,
        alarm_over_ip: true,
        alarmRoom: false,
        alarmMaintenance: false,
        cameraStorage: false,
        cameraManagement: false,
        mobileUnlimited: 0,
        mobileUnlimitedNumbers: "",
        mobileUnlimited1g: 0,
        mobileUnlimited1gNumbers: "",
        mobileUnlimited10g: 0,
        mobileUnlimited10gNumbers: "",
        mobileUnlimited5g: 0,
        mobileUnlimited5gNumbers: "",
        partner: "",
        membershipNumber: "",
        miscDescription: "",
        miscPriceOnce: 0,
        miscPriceMonthly: 0,
        pbxAmount: 0,
        telephone6863i: 0,
        telephone6865i: 0,
        telephoneN510: 0,
        telephoneS650H: 0,
        telephoneM685i: 0,
        discount: false,
        gender: 'm',
        cameraMiniDome: 0,
        cameraIrBullet: 0,
        cameraNvr: 0,
        cameraPackage: 0,
        providerName: '',
        providerNumber: '',
        misc: false,
        iban: '',
        installationDay: today,
        installationStart: {hour: parseInt(now.format('H')), minute: parseInt(now.format('m'))},
        installationEnd: {hour: parseInt(end.format('H')), minute: parseInt(end.format('m'))},
        installationCable: 0,
        installation: true,
        installationImages: [],
        installationDescription: '',
        multiTenant: true,
        agreedInstallation: false,
    },
    validate,
})(InstallationForm);

InstallationForm = connect(
    state => ({
        formData: getFormValues('wizard')(state)
    }),

)(InstallationForm);

export default InstallationForm;
